.reactour-mask {
    color: #9b9b9;
    opacity: 0.40;
    filter: alpha(opacity=40);
}

nav[data-tour-elem="navigation"] > button:first-child {
    display: none;
}

nav[data-tour-elem="navigation"] > button:last-child {
    display: none;
}
